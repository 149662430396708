<template lang="pug">
.content
  .vertical-time-line
    .step
      .circle-holder
        .number 1
      .d-flex.container
        .title {{ $t('integrationFlow.webhook.insertUrl') }}
        .description
          .input-holder
            om-input#callbackUrl.w-100(
              v-model.trim="settings.callbackUrl"
              type="text"
              :label="$t('integrationFlow.webhook.label.insertUrl')"
            )
              template(
                #error
                v-if="validations.callbackUrl.$error && !validations.callbackUrl.required"
              )
                span {{ $t('integrationFlow.webhook.error.callbackUrl') }}
    .step
      .circle-holder
        .number 2
      integration-name(:name.sync="settings.name" :validations="validations")
</template>

<script>
  import IntegrationName from '@/components/IntegrationModals/IntegrationName';

  export default {
    components: {
      IntegrationName,
    },

    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },
  };
</script>
