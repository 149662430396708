<template lang="pug">
.d-flex.container
  .title {{ $t('integrationFlow.integrationModal.general.addName') }}
  .description
    .input-holder
      om-input#name.mb-auto.w-100(
        v-model.trim="value"
        type="text"
        :placeholder="$t('integrationFlow.integrationModal.general.namePlaceholder')"
        :helpText="$t('integrationFlow.integrationModal.general.inputHelp')"
      )
        template(#error v-if="validations.name.$error && !validations.name.required")
          span {{ $t('requiredField') }}
</template>

<script>
  export default {
    name: 'IntegrationName',

    props: {
      name: {
        type: String,
      },
      validations: {
        type: Object,
      },
    },

    computed: {
      value: {
        get() {
          return this.name;
        },
        set(value) {
          this.$emit('update:name', value);
        },
      },
    },
  };
</script>
